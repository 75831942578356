import React from 'react'
import DescComp from './DescComp'
import { Grid } from '@material-ui/core'


interface FeatureCompProps {
  isLeft: boolean,
  image: string,
  str: string
}

export default function FeatureComp(props: FeatureCompProps) {
  const { isLeft, image, str } = props
  const color = isLeft ? 'rgba(235,235,235, 0.7)' : 'rgba(255,255,255,0.5)'
  return (
    <Grid container justifyContent='center' alignItems='flex-start' style={{ backgroundColor: color }}>
      {isLeft && DescComp({ title: str })}
      <img style={{ width: 350, height: 600, margin: 0, objectFit: 'contain' }} src={image} alt='itinerary' />
      {!isLeft && DescComp({ title: str })}
    </Grid>
  )
}
