import { Button, createTheme, Grid, ThemeProvider } from '@material-ui/core'
import * as mColor from '@material-ui/core/colors'
import { Apple } from '@material-ui/icons'
import { userStore } from 'data/userStore'
import firebase from 'firebase'
import { fbAuthUiConfig, processSignIn } from 'firebase/firebaseAuth'
import { firebaseApp } from 'firebase/firebaseConfig'
import 'firebaseui/dist/firebaseui.css'
import { useObserver } from 'mobx-react'
import React, { useEffect } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { useHistory } from 'react-router-dom'
import { noCapButtonStyle } from 'styles/styles'

export default function SignInPage() {

  const appleTheme = createTheme({
    palette: {
      primary: {
        main: '#111111'
      }
    }
  })

  const buttonStyle = noCapButtonStyle()

  const history = useHistory()

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const idToken = await user.getIdToken()
        localStorage.setItem('idToken', idToken)
        localStorage.setItem('locale', 'en-US')
        localStorage.setItem('os', 'web')
        localStorage.setItem('tcc', 'us;ca;dm;in;eg;kr;la;lk;cn')
        //localStorage.setItem('tcc', 'us;ca')
        console.info('fb user exist', idToken)
      } else {
        console.warn('fb user null')
      }
    })
    return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
  }, [history])

  return useObserver(() => (
    <div>
      <h1>My App</h1>
      <p>https://stsocial-dot-api-7414026823583742738-802346.uc.r.appspot.com/social/signin</p>
      {<Grid container item xs={12} justifyContent='center'>
        <ThemeProvider theme={appleTheme}>
          <Button
            classes={buttonStyle} variant='contained' color='primary' size='large' startIcon={<Apple />}
            style={{ width: 220, alignItems: 'left', justifyContent: 'left' }}>Sign in with Apple</Button>
        </ThemeProvider>
      </Grid>}
      <StyledFirebaseAuth uiConfig={fbAuthUiConfig} firebaseAuth={firebaseApp.auth()} />
    </div>
  ))
}
