import { getCountry } from 'data/countryData'
import { postStore } from 'data/postStore'
import { stText } from 'data/stringData'

const Boards = {
  FREE_FORUM: '_FREE',
  FINDING_TRAVEL_BUDDIES: '_BUDD'
}

export function addAdditionalBoard(ccList: Set<string>) {
  // 자유 게시판 추가
  ccList.add(Boards.FREE_FORUM)
  // 동행 찾기 추가
  ccList.add(Boards.FINDING_TRAVEL_BUDDIES)

  return ccList
}

export function getChipLabel(tag: string) {
  if (tag.startsWith('_')) {
    if (tag === Boards.FREE_FORUM) {
      return stText.freeForum()
    } else if (Boards.FINDING_TRAVEL_BUDDIES) {
      return stText.findingTravelBuddy()
    }
  } else {
    return getCountry(tag)?.emoji + ' ' + getCountry(tag)?.name
  }
}

export function getMainBoardLabel(tag: string) {
  if (tag.startsWith('_')) {
    if (tag === Boards.FREE_FORUM) {
      return stText.freeForum()
    } else if (Boards.FINDING_TRAVEL_BUDDIES) {
      return stText.findingTravelBuddy()
    }
  } else {
    return getCountry(postStore.currCC)?.name + ' ' + getCountry(postStore.currCC)?.emoji
  }
}

export function getSubBoardLabel(tag: string) {
  if (tag.startsWith('_')) {
    if (tag === Boards.FREE_FORUM) {
      return stText.talkAboutAnything()
    } else if (Boards.FINDING_TRAVEL_BUDDIES) {
      return stText.findingTravelBuddySub()
    }
  } else {
    return stText.talkAboutTripInfo(getCountry(postStore.currCC)?.name!)
  }
}