import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { useHistory } from 'react-router'
import { Visibility, Favorite, Comment } from '@material-ui/icons'
import { green, red, blue } from '@material-ui/core/colors'
import { Chip } from '@material-ui/core'
import { getDateString } from 'data/util'

export default function PostListCell(props: any) {
  
  const subColor = '#aaaaaa'
  const date = getDateString(props.ts)

  return useObserver(() => {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ fontSize: 17 }}>
          {props.city && <Chip
            variant='outlined'
            color='primary'
            style={{ height: 18, marginBottom: 3, marginRight: 5 }}
            size='small'
            label={props.city} />}
          <span style={{ overflowWrap: 'unset' }}>{props.title} </span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span style={{ fontSize: 12, marginRight: 10 }}>
              {props.nickname}
            </span>
            <span style={{ fontSize: 12, color: subColor }}>
              {date}
            </span>
          </div>
          <div>
            <Visibility style={{ fontSize: 10, color: green[600], marginRight: 2 }} />
            <span style={{ fontSize: 13, marginRight: 8 }}>
              {`${props.viewcnt ?? 0}`}
            </span>
            <Favorite style={{ fontSize: 10, color: red[600], marginRight: 2 }} />
            <span style={{ fontSize: 13, marginRight: 8 }}>
              {`${props.likecnt ?? 0}`}
            </span>
            <Comment style={{ fontSize: 10, color: blue[600], marginRight: 2 }} />
            <span style={{ fontSize: 13 }}>
              {`${props.commcnt ?? 0}`}
            </span>
          </div>
        </div>
      </div>
    )
  })
}
