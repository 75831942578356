import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react-lite'
import { Chip, Grid } from '@material-ui/core'
import { getCountry } from 'data/countryData'
import { postStore } from 'data/postStore'
import { userStore } from 'data/userStore'
import { addAdditionalBoard, getChipLabel } from './postData'


export default function CountryChips() {
  const ccList: Set<string> = new Set<string>()

  addAdditionalBoard(ccList)

  // 유저 국가 추가
  ccList.add(userStore.getUserCC())

  for (const cc of userStore.tripCCs) {
    ccList.add(cc)
  }

  // ccList.forEach(cc => {
  //   console.log('cc', cc)
  // })

  return useObserver(() => {
    return (
      <div style={{ width: '100%' }}>
        <Grid container direction='column'>
          {/* <Grid item xs={12}>
          <Typography variant='h6' style={{ marginLeft: 5, marginBottom: 5 }}>Select Country</Typography>
        </Grid> */}
          <Grid container direction='row' spacing={1} style={{ marginBottom: 10 }}>
            {Array.from(ccList.values()).map(cc => {
              return (
                <Grid item key={cc}>
                  <Chip
                    variant={postStore.currCC === cc ? 'default' : 'outlined'}
                    size='medium'
                    color={postStore.currCC === cc ? 'primary' : undefined}
                    label={getChipLabel(cc)} onClick={(v) => {
                      postStore.setCC(cc)
                      console.log('cc', cc)
                      postStore.fetchList(cc)
                      postStore.setPage(1)
                    }} />
                </Grid>
              )
            })}
            {userStore.email === 'wooman.labs@gmail.com' && 
              <Chip
                variant={'outlined'}
                size='medium'
                label='admin'
                onClick={() => {
                  
                }}/>}
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 1, width: '100%', backgroundColor: '#eeeeee', marginBottom: 5 }}></div>
          </Grid>
        </Grid>
      </div>
    )
  })
}

