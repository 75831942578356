import React from 'react'


interface DescCompProps {
  title: string
}

export const spec = new Map<string, number>()

// spec (base string, count of text)
spec.set('itinerary', 4)
spec.set('map', 2)
spec.set('expense', 5)
spec.set('chart', 2)
spec.set('photo', 1)
spec.set('note', 3)
spec.set('checklist', 1)
spec.set('diary', 1)

const desc = {
  'itinerary_title': 'Make your travel plans easy & clean',
  'itinerary_1': '🗺️ Simply write down your travel plans. SaveTrip turns them into a timeline.',
  'itinerary_2': '📅 It\'s very easy to change the schedule and share it.',
  'itinerary_3': '🌏 Supports all countries',
  'itinerary_4': '🖨️ Export to CSV and PDF files is available',

  'map_title': 'Mark your travel plans on the map.',
  'map_1': '🧭 Get directions with Google Maps.',
  'map_2': '📍 View the route with Google Maps.',

  'expense_title': 'Manage your travel budget and expenses efficiently.',
  'expense_1': '🌏 Supports all currencies.',
  'expense_2': '💲 Easily manage your cash budget.',
  'expense_3': '💱 Automatically convert all expenses to your home currency',
  'expense_4': '🗂️ Customizable expense categories.',
  'expense_5': '🖨️ Export to CSV and PDF files for your convenience.',

  'chart_title': 'Travel expense statistics',
  'chart_1': '📊 Visualize travel expense usage through graphical statistics.',
  'chart_2': '🗂️ Categorize expenses by category, person, daily spending, and payment method.',

  'photo_title': 'Manage expenses with photo',
  'photo_1': '📷 Capture your journey with photos.',

  'note_title': 'Organize all your travel documents effortlessly.',
  'note_1': '📁 Attach vouchers, documents, and photos to your notes.',
  'note_2': '📋 Supports images, PDF, XLS, and DOC formats.',
  'note_3': '📎 Clip web pages from your browser directly into your notes.',

  'checklist_title': 'Effortlessly create travel checklists.',
  'checklist_1': '☑️ Generate packing checklists for your travels.',

  'diary_title': 'Capture your journey with photos.',
  'diary_1': '📷 Preserve your trip memories with photographs.'

}


export default function DescComp(props: DescCompProps) {
  const { title } = props
  const itemNum = spec.get(title)
  const items = Array.from(Array(itemNum).keys())
  const descMap = new Map(Object.entries(desc))
  return (
    <div style={{ width: 350, padding: 20 }}>
      <h1>{descMap.get(`${title}_title`)} </h1>
      {
        items.map((v, i) => <h2 key={v}> {descMap.get(`${title}_${i + 1}`)} </h2>)
      }
    </div>
  )
}
