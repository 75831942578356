import React, { useEffect, useRef, useState } from 'react'
import { Grid, Button, Card } from '@material-ui/core'

export default function ShareCodePage() {
  return (
    <div className='double-gradient' style={{ flexDirection: 'column', display: 'flex', padding: 10, backgroundColor: 'gray', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      sharecode
      <Button variant='outlined' href='https://savetrip.me/sharecode/12' style={{ marginBottom: 15, marginLeft: 36 }} target='_blank'>Get Invite</Button>
    </div>
  )
}