declare global {
  interface Window {
    // 여기 정의 되기 전에 index.html script 에서 정의 되어 있어야함.
    updateToken: () => void
    postAdded: () => void
  }
}

/* mobile webview 와 인터페이스 하려면 android 에사 @JavascriptInterface 함수 작성 후  webView.addJavascriptInterface(this, "android"); 추가
  front 에 index.html script 부분에 함수 작성하면 window.xxx() 로 front에서 android의 함수 호출가능
*/

export function updateTokenWebView() {
  try {
    window.updateToken()
  } catch (e) {
    console.log(e)
  }
}

export function postAddedWebView() {
  try {
    window.postAdded()
  } catch (e) {
    console.log(e)
  }
}