import React from 'react'
import appStore from '../../image/appstore.png'
import googlePlay from '../../image/googleplay.png'

export default function StoreBadgeComp() {
  return (
    <div style={{ marginBottom: 30}}>
      <a href='https://apps.apple.com/app/id1500591552' target='_blank' rel='noreferrer'>
        <img style={{ width: 180, height: 50, objectFit: 'contain' }} src={appStore} alt='appStore' />
      </a>
      <a href='https://play.google.com/store/apps/details?id=com.woomanlabs.mytravelnote'
        target='_blank' rel='noreferrer'>
        <img style={{ width: 180, height: 50, objectFit: 'contain' }} src={googlePlay} alt='playStore' />
      </a>
    </div>
  )
}
