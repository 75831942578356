import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { postStore } from 'data/postStore'
import { List, ListItem, ListItemText, Grid, CircularProgress, LinearProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { toJS } from 'mobx'
import { useHistory } from 'react-router-dom'
import PostListCell from './PostListCell'

export default function PostList() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (postStore.currCC !== '') {
      setLoading(true)
      postStore.fetchList(postStore.currCC).then(() => setLoading(false))
    }

  }, [postStore.currCC])

  return useObserver(() => {
    const currPageData = postStore.postList.slice(
      (postStore.currPage - 1) * postStore.dataPerPage,
      postStore.currPage * postStore.dataPerPage)

    const pageCount = ~~(postStore.postList.length / postStore.dataPerPage) + (postStore.postList.length % postStore.dataPerPage === 0 ? 0 : 1) //정수화
    console.log('page', pageCount)
    console.log('postStore.currPage', postStore.currPage)
    return (
      <Grid container direction='column' spacing={1} >

        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {loading ? <LinearProgress style={{ width: '100%', marginTop: 3 }} /> : <span style={{height: 7}}/>}
          </div>
          <List>
            {currPageData.map(v => {
              return (
                <ListItem key={v._id} dense={false} style={{ paddingLeft: 5, paddingRight: 5 }} button onClick={() => {
                  history.push(`/social/m/posts/detail/${v._id}`)
                }}>
                  <PostListCell {...v} />
                </ListItem>
              )
            })}
          </List>
        </Grid>
        <Grid item xs={12} >
          <Pagination style={{ justifyContent: 'center', display: 'flex', marginTop: 15 }} page={postStore.currPage} count={pageCount} color='primary' onChange={(e, v) => postStore.setPage(v)} />
        </Grid>
      </Grid>
    )
  })
}
