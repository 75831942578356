import React from 'react'
import { Route } from 'react-router-dom'
import PostDetailPage from './social/post/PostDetailPage'
import SignInPage from './social/SignInPage'
import SocialMainPage from './social/SocialMainPage'
import PostWriterPage from './social/post/PostWriterPage'
import ProfilePage from './social/profile/ProfilePage'
import AffiliateMainPage from './affiliate/AffiliateMainPage'
import MainPage from './main/MainPage'
import ShareCodePage from './sharecode/ShareCodePage'

export default function Router() {
  return (
    <div>
      <Route exact path='/' component={MainPage} />
      <Route exact path='/social/m' component={SocialMainPage} />
      <Route path='/social/m/profile' component={ProfilePage} />
      <Route path='/social/m/write' component={PostWriterPage} />
      <Route path='/social/m/signin' component={SignInPage} />
      <Route path='/social/m/posts/detail' component={PostDetailPage} />
      <Route path='/affiliate' component={AffiliateMainPage} />
      <Route path='/sharecode' component={ShareCodePage} />
    </div>
  )
}

