import React, { useRef, useState } from 'react'
import { useObserver } from 'mobx-react'
import { TextField, Grid, Button, Box, IconButton, Typography, LinearProgress, Snackbar } from '@material-ui/core'
import { apiGetUserCustomToken, apiPostPost } from 'data/apis'
import { useHistory, useLocation } from 'react-router-dom'
import { userStore } from 'data/userStore'
import { postStore } from 'data/postStore'
import firebase from 'firebase'
import { getRandomInt, imageResizer } from 'data/util'
import CountryChips from './CountryChips'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import ClearRoundedIcon from '@material-ui/icons/ClearRounded'
import PhotoRoundedIcon from '@material-ui/icons/PhotoRounded'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import PhotoIcon from '@material-ui/icons/Photo'
import { Alert } from '@material-ui/lab'
import { Post } from '../../../../../backend/src/model/modelTypes'
import { stText } from 'data/stringData'
import { postAddedWebView } from 'data/webviewInterface'

export default function PostWriterPage() {
  const titleRef: React.Ref<any> = useRef()
  const cityRef: React.Ref<any> = useRef()
  const contentRef: React.Ref<any> = useRef()
  const history = useHistory()
  // let attachedImg: Blob

  const location = useLocation<any>()
  const data = location.state?.data as Post

  let tempAttach = null
  if (data?.photopath && data?.photopath[0]) {
    tempAttach = {} as any
    tempAttach.name = 'photo.jpg'
  }
  const [attachedImg, setAttachedImg] = useState<any>(tempAttach)
  const [isUploading, setIsUploading] = useState(false)
  const [failMsg, setFailMsg] = useState('')

  const makeUploadPath = (id: string) => {
    const now = new Date()
    return `${postStore.currCC}/${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()}/${id}-${getRandomInt(0, 999999)}.jpg`
  }

  const upload = async () => {
    const title = titleRef.current.value as string
    const content = contentRef.current.value as string

    if (!title || title.length === 0) {
      setFailMsg(stText.writeTitle())
      return false
    }
    if (!content || content.length === 0) {
      setFailMsg(stText.writeContents())
      return false
    }

    let imgUrl: string | null = null
    let imgPath: string | null = null
    try {
      setIsUploading(true)

      const postModel: any = {
        title: title,
        content: content,
        city: cityRef.current?.value,
        nickname: userStore.nickname,
        forum: 'country',
        cc: postStore.currCC,
        lc: userStore.getUserLC()
      }

      if (data) {
        postModel._id = data._id
      }

      if (data?.photopath && data?.photopath[0]) {
        // delete uploaded image
        if (attachedImg === null || attachedImg.name !== 'photo.jpg') {
          console.log('must delete prev uploaded pic')
          await firebase.storage().ref(data.photopath[0]).delete()
          postModel.photourl = null
          postModel.photopath = null
        }
      }

      if (attachedImg && attachedImg?.name !== 'photo.jpg') {
        imgPath = makeUploadPath(userStore.fbid)
        console.log('upload path', imgPath)

        if (firebase.auth().currentUser == null) {
          const tokenRes = await apiGetUserCustomToken()
          await firebase.auth().signInWithCustomToken(tokenRes.data)
        }
        const storageRef = firebase.storage().ref()
        const upRef = storageRef.child(imgPath)

        try {
          const smallImg = await imageResizer(attachedImg)
          const res = await upRef.put(smallImg)
          imgUrl = await res.ref.getDownloadURL()
          if (imgUrl) {
            postModel.photourl = [imgUrl]
            postModel.photopath = [imgPath]
          }
          console.log('url', imgUrl)
        } catch (err) {
          console.log('err', err)
        }
      }

      console.log('postModel', postModel)
      await apiPostPost(postModel)
      return true
    } catch (err) {
      if (imgPath) {
        // posting failed, so delete img file from fb storage
        await firebase.storage().ref(imgPath).delete()
      }
      setFailMsg(stText.serverError())
      console.log(err)
      return false
    }
  }

  return useObserver(() => (
    <Grid container direction='column' spacing={2} >
      <Grid item xs={12}>
        <CountryChips />
      </Grid>
      <Grid item xs={12}>
        <TextField id='title' defaultValue={data?.title} inputRef={titleRef} label={stText.Title()} variant='outlined' multiline style={{ width: '100%' }} />
      </Grid>
      {!postStore.currCC.startsWith('_FREE') && <Grid item xs={12}>
        <TextField id='city' defaultValue={data?.city} inputRef={cityRef} label={stText.CityOrRegion()} variant='outlined' multiline style={{ width: '100%' }} />
      </Grid>}
      <Grid item xs={12}>
        <TextField id='content' defaultValue={data?.content} inputRef={contentRef} label={stText.Contents()} variant='outlined' multiline rows={10} style={{ width: '100%' }} />
      </Grid>
      {attachedImg && <Grid item xs={12}>
        <div style={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
          <PhotoIcon color='disabled' style={{ marginTop: 3 }} />
          <span style={{ fontSize: 16, marginTop: 5, marginLeft: 2, marginRight: 5 }}>{attachedImg?.name}</span>
          <IconButton aria-label='upload picture' component='span' size='small' onClick={() => setAttachedImg(null)}>
            <HighlightOffRoundedIcon color='error' />
          </IconButton>
        </div>
      </Grid>}
      {isUploading && <Grid item xs={12}>
        <LinearProgress />
      </Grid>}
      <Grid item xs={12} >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          <Button variant='contained' startIcon={<ClearRoundedIcon />} style={{ width: 110 }} onClick={() => history.goBack()} disabled={isUploading}>{stText.CANCEL()}</Button>
          <Button variant='contained' color='secondary' startIcon={<PhotoRoundedIcon />} component='label' style={{ width: 110 }} disabled={isUploading}>
            {stText.PHOTO()}
            <input type='file' accept='image/*' hidden onChange={(data) => {
              if (data.target.files) {
                console.log('file name', data?.target?.files[0]?.name)
                setAttachedImg(data?.target?.files[0])
              }
            }} />
          </Button>
          <Button variant='contained' color='primary' startIcon={<DoneRoundedIcon />} style={{ width: 110 }} onClick={() => {
            setIsUploading(true)
            upload().then((res) => {
              if (res) {
                postAddedWebView()
                history.goBack()
              }
            }).finally(() => setIsUploading(false))
          }} disabled={isUploading}>{stText.SAVE()}</Button>
        </div>
      </Grid>
      <Snackbar open={failMsg !== ''} autoHideDuration={6000} onClose={() => setFailMsg('')} >
        <Alert severity='error'>
          {failMsg}
        </Alert>
      </Snackbar>
    </Grid>
  ))
}
