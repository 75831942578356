import { observable } from 'mobx'
import firebase from 'firebase'
import { apiGetUser, apiPostUser } from './apis'

export const userStore = observable({
  fbid: '',
  email: '',
  nickname: '',
  tripCCs: [] as string[],
  locale: '',
  os: '',
  setOS(os: string) {
    this.os = os
  },
  setLocale(locale: string) {
    this.locale = locale
  },
  getUserCC() {
    if (this.locale && this.locale.length > 0) {
      return this.locale.substring(this.locale.length - 2, this.locale.length).toUpperCase()
    } else {
      return 'US'
    }
  },
  getUserLC() {
    if (this.locale && this.locale.length > 0) {
      return this.locale.substring(0, 2).toUpperCase()
    } else {
      return 'EN'
    }
  },
  setTripCCs(ccList: string[]) {
    this.tripCCs = ccList
  },
  setNickName(nick: string) {
    this.nickname = nick
  },
  setEmail(email: string) {
    this.email = email
  },
  setFbid(fbid: string) {
    this.fbid = fbid
  },
  async fetchUserInfo() {
    const res = await apiGetUser()
    console.log('res', res)

    if (res.data.user === null) {
      return -1
    } else if (res) {
      this.nickname = res.data.user.nickname
      this.fbid = res.data.user.userfbid
      this.email = res.data.email
    }
    return 1
  }
})
