import axios, { AxiosResponse } from 'axios'
import { Comment, Post } from '../../../backend/src/model/modelTypes'
import { userStore } from './userStore'
import { updateTokenWebView } from './webviewInterface'


// const baseUrl: string = process.env.NODE_ENV === 'production' ? 'https://st-server-r6fanil6ua-du.a.run.app/backend' : 'http://localhost:8005'        // use cloud run backend
const baseUrl: string = process.env.NODE_ENV === 'production' ? 'https://api-7414026823583742738-802346.uc.r.appspot.com' : 'http://localhost:8081'     // use app engine backend

const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 1000 * 10
})

apiClient.interceptors.response.use(response => response, error => {
  if (error.response?.status === 401) {
    if (userStore.os.toLowerCase() === 'android') {
      updateTokenWebView()
    }
  }
  return Promise.reject(error)
})

function getHeader() {
  const token = localStorage.getItem('idToken')

  return {
    'Content-Type': 'application/json',
    'fbauthorization': token
  }
}

// user
export async function apiPostUser(data: any) {
  return await apiClient.post('/v1/user', data, { headers: getHeader() })
}

export async function apiGetUser() {
  return await apiClient.get('/v1/user', { headers: getHeader() })
}

export async function apiGetUserPosts() {
  return await apiClient.get('/v1/user/posts', { headers: getHeader() })
}

export async function apiGetUserCustomToken() {
  return await apiClient.get('/v1/user/customtoken', { headers: getHeader() })
}


// post
export async function apiPostPost(data: Post) {
  if (localStorage.getItem('type') === 'dev' && !data._id) {
    data.type = 'dev'
  }
  return await apiClient.post('/v1/social/post', data, { headers: getHeader() })
}

export async function apiGetPost(id: string) {
  return await apiClient.get(`/v1/social/post?pid=${id}`, { headers: getHeader() })
}

export async function apiDelPost(id: string) {
  return await apiClient.delete(`/v1/social/post?pid=${id}`, { headers: getHeader() })
}

export async function apiGetPosts(cc: string) {
  const type = localStorage.getItem('type')
  return await apiClient.get(type === 'dev' ? `/v1/social/posts?cc=${cc}&type=dev` : `/v1/social/posts?cc=${cc}`, { headers: getHeader() })
}

export async function apiSearchSocial(word: string, cc: string) {
  return await apiClient.get(`/v1/social/search?word=${word}&cc=${cc}`, { headers: getHeader() })
}

// comment
export async function apiPostComment(data: Comment) {
  if (localStorage.getItem('type') === 'dev' && !data._id) {
    data.type = 'dev'
  }
  return await apiClient.post('/v1/social/comment', data, { headers: getHeader() })
}

export async function apiDelComment(cid: string) {
  return await apiClient.delete(`/v1/social/comment?cid=${cid}`, { headers: getHeader() })
}