import { userStore } from 'data/userStore'
import firebase from 'firebase'
import { firebaseApp } from './firebaseConfig'

export const fbAuthUiConfig: firebaseui.auth.Config = {
  // Popup signin flow rather than redirect flow default redirect popup.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '/social/m',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ]
}

export function processSignIn(user: firebase.User, history: any) {
  if (user) {
    history.replace('/social')
    console.info('fb user exist')
  } else {
    console.warn('fb user null')
  }
}

export function processSignOut(history: any) {
  firebaseApp.auth().signOut().then(() => {
    history.push('social/signin')
  })
}
