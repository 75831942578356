// https://stackoverflow.com/questions/49996456/importing-json-file-in-typescript
import countries from './countries.json'

const countryData = new Map<string, CountryType>()

export interface CountryType {
  name: string
  alpha2: string
  alpha3: string
  numeric: string
  locales: string[]
  default_locale: string
  currency: string
  languages: string[]
  capital: string
  emoji: string
  emojiU: string
  fips: string
  internet: string
  continent: string
  region: string
  alternate_names?: undefined
}

for (const cc of countries) {
  countryData.set(cc.alpha2, cc as CountryType)
}

export function getCountry(cc: string | undefined) {
  return cc ? countryData.get(cc.toUpperCase()) : null
}