import React from 'react'
import mainIcon from '../../image/main_icon.png'
import mainLetter from '../../image/savetrip_letter_color.png'

export default function TitleComp() {
  const iconSize = 65
  return (
    <div  style={{ marginTop: 20, marginBottom: 20 }}>
      <img style={{ width: iconSize, height: iconSize, marginBottom: 20 }} src={mainIcon} alt='savetrip_icon' />
      <img style={{ width: 210, height: 95, objectFit: 'contain', marginLeft: 15 }} src={mainLetter} alt='savetrip_logo' />
    </div>
  )
}
