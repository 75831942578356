import React, { useEffect, useRef, useState } from 'react'
import { Button, Chip, CircularProgress, Grid, IconButton, Paper, Popper, Snackbar, TextField } from '@material-ui/core'
import { useStyles } from 'styles/styles'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { postStore } from 'data/postStore'
import { useObserver } from 'mobx-react'
import { apiDelComment, apiDelPost, apiGetPost, apiGetUserCustomToken, apiPostComment, apiPostPost } from 'data/apis'
import { userStore } from 'data/userStore'
import { green, red, blue } from '@material-ui/core/colors'
import { Visibility, Favorite, FavoriteBorder, Comment as CommentIcon, Edit } from '@material-ui/icons'
import { getCountry } from 'data/countryData'
import { Comment, Post } from '../../../../../backend/src/model/modelTypes'
import CommentList from 'components/social/comment/CommentList'
import { stText } from 'data/stringData'
import firebase from 'firebase'
import { getDateString } from 'data/util'

export default function PostDetailPage() {
  const classes = useStyles()

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const postId = location.pathname.replace(match.url + '/', '')
  console.log('postId', postId)
  const [data, setData] = useState<Post | null>(null)
  const [comments, setComments] = useState<Comment[]>([])
  const locale = userStore.locale
  const commentRef: React.Ref<any> = useRef()

  const getPostData = async () => {
    const res = await apiGetPost(postId)
    const postData = res.data.post
    console.log('detail', postData)
    setData(postData)
    setComments(res.data.comments)
    // update view count
    if (postData.userfbid !== userStore.fbid) {
      const cnt = postData.viewcnt ?? 0
      postData.viewcnt = cnt + 1
      await apiPostPost(postData)
    }
  }

  const uploadComment = async () => {
    const comment = commentRef.current.value as string
    console.log('related comment id', commentRef.current.relatedId)
    if (!comment.startsWith('@')) {
      console.log('remove related commentId')
      commentRef.current.relatedId = undefined
    }
    if (comment && comment.length > 0) {
      if (commentRef.current.editingComment) {
        // modify
        await apiPostComment({
          _id: commentRef.current.editingComment._id,
          postid: data!._id ?? '',
          userfbid: userStore.fbid,
          nickname: userStore.nickname,
          comment: comment,
          previd: commentRef.current.editingComment.previd,
          photourl: commentRef.current.editingComment.photourl
        })
      } else {
        // create
        await apiPostComment({
          postid: data!._id ?? '',
          userfbid: userStore.fbid,
          nickname: userStore.nickname,
          comment: comment,
          previd: commentRef.current.relatedId
        })
      }


      //reset input ref
      commentRef.current.relatedId = undefined
      commentRef.current.editingComment = undefined
      commentRef.current.value = null
      getPostData()
    }
  }

  const deletePost = async () => {
    // must delete photo files in front-end
    if (data?._id) {
      if (data?.photopath && data.photopath[0]) {
        const tokenRes = await apiGetUserCustomToken()
        await firebase.auth().signInWithCustomToken(tokenRes.data)
        for (const path of data.photopath) {
          try {
            await firebase.storage().ref(path).delete()
          } catch (err) {
            console.log('err', err)
          }
        }
      }
      await apiDelPost(data!._id)
      history.goBack()
    }
  }

  const editPost = () => {
    // history.push('/social/m/write')
    history.push({
      pathname: '/social/m/write',
      state: { data: data }
    })
  }

  useEffect(() => {
    getPostData()
  }, [postId])

  const deleteComment = (id: string) => {
    const filtered = comments.filter((v) => v._id !== id ? v : null)
    console.log('filtered', filtered)
    setComments(filtered)
  }

  return useObserver(() => {

    console.log('data.photourl[0]', data?.photourl ? data.photourl[0] : '')

    const subColor = '#aaaaaa'
    return (
      <Grid container direction='column' spacing={1} >
        <Grid item xs={12}>
          <div style={{ fontSize: 18 }}>
            {data?.city && <Chip
              variant='outlined'
              color='primary'
              style={{ height: 18, marginBottom: 3, marginRight: 5 }}
              size='small'
              label={data.city} />}
            <span style={{ overflowWrap: 'unset' }}>{getTitle(data)}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: 1, width: '100%', backgroundColor: '#eeeeee' }}></div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <span style={{ fontSize: 12, marginRight: 10, fontWeight: 'bold' }}>
                {data?.nickname}
              </span>
              <span style={{ fontSize: 12, color: subColor }}>
                {data && getDateString(data!.ts ?? 0)}
              </span>
            </div>
            <div>
              <Visibility style={{ fontSize: 10, color: green[600], marginRight: 2 }} />
              <span style={{ fontSize: 13, marginRight: 8 }}>
                {`${data?.viewcnt ?? 0}`}
              </span>
              <Favorite style={{ fontSize: 10, color: red[600], marginRight: 2 }} />
              <span style={{ fontSize: 13, marginRight: 8 }}>
                {`${data?.likecnt ?? 0}`}
              </span>
              <CommentIcon style={{ fontSize: 10, color: blue[600], marginRight: 2 }} />
              <span style={{ fontSize: 13 }}>
                {`${data?.commcnt ?? 0}`}
              </span>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ fontSize: 14, marginTop: 10, marginBottom: 10, whiteSpace: 'pre-line' }}>{data?.content}</div>
        </Grid>
        {data?.photourl && data?.photourl[0] && <Grid item xs={12}>
          <div>{<img style={{ width: 'inherit', height: 'auto', maxWidth: '100%' }} src={data?.photourl[0]} alt='' />}</div>
        </Grid>}
        <Grid item xs={12}>
          {getBottomView(data, setData, deletePost, editPost)}
        </Grid>
        {comments?.length > 0 && <CommentList comments={comments} commentInputRef={commentRef} deleteComment={deleteComment} />}
        <Grid item xs={12}>
          <div style={{ height: 1, width: '100%', backgroundColor: '#eeeeee', marginBottom: 5 }}></div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField id='Comment' inputRef={commentRef} label={stText.comments()} variant='standard' multiline style={{ width: '78%', marginBottom: 5 }} />
            <Button variant='text' color='primary' style={{ width: '20%' }} onClick={uploadComment}>{stText.SAVE()}</Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained' color='primary' style={{ width: '100%', marginTop: 6 }} onClick={() => history.goBack()}>{stText.BACK()}</Button>
        </Grid>
      </Grid>
    )
  })
}

function getTitle(data: Post | null): string {
  if(data?.cc.startsWith('_')) {
    // in case of freeboard
    return data?.title ?? ''
  } else {
    return (data?.title ?? '') + ' ' + (getCountry(data?.cc)?.emoji ?? '')
  }
}

function getBottomView(data: Post | null, setData: any, deletePost: any, editPost: any) {

  const [popup, setPopup] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [snackBar, setSnackBar] = useState(false)

  const isFavorite = data?.likeusers?.includes(userStore.fbid)
  if (!userStore.fbid) {
    userStore.fetchUserInfo()
  }
  const myPost = data?.userfbid === userStore.fbid

  if (!data) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress style={{ marginTop: 3 }} />
      </div>
    )
  } else return myPost ? (<div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
    <Popper id={data._id} open={popup} anchorEl={anchorEl}>
      <Paper style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <div>
            <div style={{ padding: 10, fontSize: 15 }}>{stText.doYouWantToEdit()}</div>
          </div>
          <div>
            <Button variant='text' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0 }} onClick={() => setPopup(false)}>{stText.NO()}</Button>
            <Button variant='text' color='primary' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0 }} onClick={() => {
              setPopup(false)
              editPost()
            }}>{stText.EDIT()}</Button>
            <Button variant='text' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0, color: 'red' }} onClick={() => {
              setPopup(false)
              setSnackBar(true)
            }}>{stText.DELETE()}</Button>
          </div>
        </div>
      </Paper>
    </Popper>
    <IconButton aria-label='edit' component='span' color='primary' size='small' onClick={(ev: any) => {
      setAnchorEl(ev.currentTarget)
      setPopup(true)
    }}><Edit />
    </IconButton>
    <Snackbar open={snackBar} autoHideDuration={6000} onClose={() => setSnackBar(false)} message={stText.areYouSureYouWantToDelete()} action={
      <Button size='small' style={{ color: 'red' }} onClick={() => {
        setSnackBar(false)
        deletePost()
      }}>
        {stText.DELETE()}
      </Button>
    } />
  </div>) : (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
      <IconButton aria-label='like' component='span' size='small' onClick={async () => {
        if (data?.userfbid !== userStore.fbid) {
          const newData = data!
          if (isFavorite) {
            newData!.likeusers?.forEach((val, idx) => {
              if (val === userStore.fbid) {
                newData!.likeusers?.splice(idx, 1)
              }
            })
          } else {
            newData!.likeusers?.push(userStore.fbid)
          }
          newData!.likecnt = newData!.likeusers?.length ?? 0
          await apiPostPost(newData)
          setData({
            ...newData
          })
        }
      }}>
        {isFavorite ? <Favorite style={{ color: 'red' }} /> : <FavoriteBorder style={{ color: 'red' }} />}
      </IconButton>
    </div>
  )
}
