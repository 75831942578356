import React, { useEffect, useState } from 'react'
import { useObserver } from 'mobx-react'
import { Comment } from '../../../../../backend/src/model/modelTypes'
import { getDateString } from 'data/util'
import { Button, createStyles, Snackbar, Paper, Popper } from '@material-ui/core'
import { noCapButtonStyle } from 'styles/styles'
import { stText } from 'data/stringData'
import { userStore } from 'data/userStore'
import { apiDelComment } from 'data/apis'

export default function CommentListCell(props: { data: Comment, commentInputRef: React.RefObject<any>, deleteComment: any }) {
  const { data, commentInputRef, deleteComment } = props

  const subColor = '#aaaaaa'
  const date = getDateString(data!.ts!)
  const buttonStyle = noCapButtonStyle()

  const [popup, setPopup] = useState(false)
  const [snackBar, setSnackBar] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)


  return useObserver(() => {
    console.log('props.data', data)
    return (
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Button classes={buttonStyle} variant='text' color='primary' style={{ fontSize: 12, paddingLeft: 0, justifyContent: 'flex-start', minWidth: 0 }} onClick={() => {
              // create new comment
              commentInputRef.current.relatedId = data._id    // define custom property 'relatedId'
              commentInputRef.current.value = `@${data.nickname} `
              commentInputRef.current.focus()
              commentInputRef.current.scrollIntoView()
            }}>{data.nickname}</Button>
            <span style={{ fontSize: 12, color: subColor }}>
              {date}
            </span>
          </div>
          {
            userStore.fbid === data.userfbid ?
              <div>
                <Popper id={data._id} open={popup} anchorEl={anchorEl}>
                  <Paper style={{ display: 'flex', justifyContent: 'center' }}>
                    <div>
                      <div style={{ padding: 10, fontSize: 15 }}>{stText.doYouWantToEdit()}  </div>
                      <div>
                        <Button variant='text' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0 }} onClick={() => setPopup(false)}>{stText.NO()}</Button>
                        <Button variant='text' color='primary' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0 }} onClick={() => {
                          // edit comment
                          setPopup(false)
                          commentInputRef.current.editingComment = data    // define custom property 'editingComment'
                          commentInputRef.current.value = data.comment
                          commentInputRef.current.focus()
                          commentInputRef.current.scrollIntoView()

                        }}>{stText.EDIT()}</Button>
                        <Button variant='text' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0, color: 'red' }} onClick={() => {
                          setPopup(false)
                          setSnackBar(true)

                        }}>{stText.DELETE()}</Button>
                      </div>
                    </div>
                  </Paper>
                </Popper>
                <Button variant='text' color='primary' style={{ fontSize: 11, paddingLeft: 0, paddingRight: 0 }} onClick={(ev) => {
                  setAnchorEl(ev.currentTarget)
                  setPopup(true)
                }}>{stText.EDIT()}</Button>
              </div>
              : <></>
          }
        </div>
        <div style={{ fontSize: 14, whiteSpace: 'pre-line' }}>
          <span style={{ overflowWrap: 'unset' }}>{data.comment} </span>
        </div>
        <Snackbar open={snackBar} autoHideDuration={6000} onClose={() => setSnackBar(false)} message={stText.areYouSureYouWantToDelete()} action={
          <Button size='small' style={{ color: 'red' }} onClick={() => {
            apiDelComment(data._id!).then(v => {
              deleteComment(data._id)
              setSnackBar(true)
            })
          }}>
            {stText.DELETE()}
          </Button>
        } />
      </div>
    )
  })
}

