import { useObserver } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import itinerary from '../../image/device1s.png'
import map from '../../image/device2s.png'
import expense from '../../image/device3s.png'
import chart from '../../image/device4s.png'
import photo from '../../image/device5s.png'
import note from '../../image/device6s.png'
import checklist from '../../image/device7s.png'
import diary from '../../image/device8s.png'
import TitleComp from './TitleComp'
import StoreBadgeComp from './StoreBadgeComp'
import FeatureComp from './FeatureComp'
import BottomComp from './BottomComp'

export default function MainPage() {
  return (
    <div>
      <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'center', padding: 10, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
        {TitleComp()}
        {StoreBadgeComp()}
        {FeatureComp({ isLeft: true, image: itinerary, str: 'itinerary' })}
        {FeatureComp({ isLeft: false, image: map, str: 'map' })}
        {FeatureComp({ isLeft: true, image: expense, str: 'expense' })}
        {FeatureComp({ isLeft: false, image: chart, str: 'chart' })}
        {FeatureComp({ isLeft: true, image: photo, str: 'photo' })}
        {FeatureComp({ isLeft: false, image: note, str: 'note' })}
        {FeatureComp({ isLeft: true, image: checklist, str: 'checklist' })}
        {FeatureComp({ isLeft: false, image: diary, str: 'diary' })}
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(225, 225, 225)', width: '100%', paddingBottom: 20, paddingTop: 20 }}>
          {BottomComp()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(225, 225, 225)', width: '100%', paddingBottom: 20 }}>
          © 2024 Wooman.labs LLC
        </div>
      </div>
    </div>
  )
}