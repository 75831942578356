import { Grid, List, ListItem } from '@material-ui/core'
import { apiGetPosts } from 'data/apis'
import { useObserver } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { Comment, Post } from '../../../../../backend/src/model/modelTypes'
import CommentListCell from './CommentListCell'

export default function CommentList(props: { comments: Comment[], commentInputRef: React.RefObject<any>, deleteComment: any }) {
  const { comments, commentInputRef, deleteComment } = props

  return useObserver(() => {
    return (
      <Grid container direction='column' spacing={1} style={{ backgroundColor: '#fafafa' }}>
        <Grid item xs={12}>
          <List>
            {comments?.map(v => {
              return (
                <ListItem key={v._id} dense={false} style={{ paddingLeft: 5, paddingRight: 5 }} >
                  <CommentListCell data={v} commentInputRef={commentInputRef} deleteComment={deleteComment} />
                </ListItem>
              )
            })}
          </List>
        </Grid>
      </Grid>
    )
  })
}