/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useRef, useState } from 'react'
import qs from 'qs'
import { Grid, Button, Card, Link } from '@material-ui/core'
import agoda from '../../image/agoda.svg'
import airalo from '../../image/airalo.svg'
import vnbg from '../../image/vnbg2.png'
import jpbg from '../../image/jpbg2.png'
import thbg from '../../image/thbg.png'
import twbg from '../../image/twbg.png'
import aubg from '../../image/aubg.png'
import phbg from '../../image/phbg.png'
import frbg from '../../image/frbg.png'
import bg1 from '../../image/bg1.png'

import klookSaveTrip from '../../image/klookst.png'
import { getCountry } from 'data/countryData'

import '../../styles/index.css'
import { getCityNameByLocale, klookCountryMap, makeKlookLocale, makeKlookUrl } from './klookCityList'
import { logToFb } from 'firebase/firebaseAnalytics'

type TextType = {
  topBanner: string,
  hotelTitle: string,
  agodaButton: string,
  agodaButtonLink: string,
  simCardTitle: string,
  airaloButton: string,
  airaloButtonLink: string,
  klookTitle: string,
  klookButton: string,
}

const textKo: TextType = {
  topBanner: '여행을 준비해 보세요!',
  hotelTitle: '호텔',
  agodaButton: '특별 할인 쿠폰 받고 호텔 예약 하기',
  agodaButtonLink: 'https://www.agoda.com/deals?pcs=1&cid=1818864&hl=en&pslc=1',
  simCardTitle: '심카드',
  airaloButton: '편리한 eSIM 구매 하기(15% 할인)',
  airaloButtonLink: 'https://discover.airalo.com/savetrip/',
  klookTitle: '액티비티, 현지 투어, 티켓, 패스',
  klookButton: '쿠폰팩, 프로모션 상품 둘러보기'
}

const textEn: TextType = {
  topBanner: 'Get ready to go on a trip!',
  hotelTitle: 'Hotel',
  agodaButton: 'Get special coupons',
  agodaButtonLink: 'https://www.agoda.com/deals?pcs=1&cid=1818864&hl=en&pslc=1',
  simCardTitle: 'SIM Card',
  airaloButton: 'Buy eSIM (15% discount)',
  airaloButtonLink: 'https://discover.airalo.com/savetrip_en/',
  klookTitle: 'Tours Attraction & Train Tickets',
  klookButton: 'explore Klook-only offers, coupon pack'
}

export default function AffiliateMainPage() {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const os = params.os as string
  const tcc = params.tcc as string
  const startDate = params.st as string
  const endDate = params.ed as string
  const city = params.city as string
  const locale = params.locale as string  //lang-country
  console.log(params)

  let text: TextType
  const cardBackgroud = 'rgba(255, 255, 255, 0.8)'

  if (locale && locale.startsWith('ko')) {
    text = textKo
  } else {
    text = textEn
  }

  logToFb('aff_open', { device: os, tcc, locale })
  let bg = tcc.includes('VN') ? vnbg : bg1
  bg = tcc.includes('JP') ? jpbg : bg
  bg = tcc.includes('AU') ? aubg : bg
  bg = tcc.includes('TW') ? twbg : bg
  bg = tcc.includes('PH') ? phbg : bg
  bg = tcc.includes('FR') ? frbg : bg
  bg = tcc.includes('TH') ? thbg : bg



  return (
    // use absolute for full screen
    <div className='double-gradient' style={{
      backgroundImage: `url(${bg})`, // 이미지 경로를 url로 감싸서 사용
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat', flexDirection: 'column', display: 'flex', padding: 10, backgroundColor: 'gray', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0
    }}>
      {/* <div style={{ width: '100%' }}>
        <img src={stlogo} style={{ width: '25%', marginLeft: 10, marginTop: 8, objectFit: 'contain' }} />
      </div> */}
      <div style={{ width: '100%', textAlign: 'center', fontSize: 22, fontWeight: 'bold', color: '#F8DB99', marginTop: 20, marginBottom: 20 }}>
        {text.topBanner}
      </div>
      <Card variant='outlined' style={{ width: '100%', marginTop: 15, backgroundColor: cardBackgroud }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: '#333333', padding: 10 }}>
          # {text.klookTitle}
        </div>
        <img src={klookSaveTrip} width='45%' style={{ marginLeft: 10, scale: 'initial', opacity: 1 }} />
        {
          tcc.split(':').map(tccItem => {
            const cityMap = klookCountryMap.get(tccItem)
            if (cityMap) {
              return (
                <div style={{ padding: 10 }} key={tccItem}>
                  {
                    Array.from(cityMap.entries()).map((value, index) => {
                      return <Button variant='outlined' key={index} href={makeKlookUrl(makeKlookLocale(locale), tccItem, value[0])} style={{ margin: 5, backgroundColor: 'white' }} onClick={() => {
                        logToFb('aff_klook', { device: os, tcc, locale, city: value[0] })
                      }}>{getCountry(tccItem)!.emoji + ' ' + getCityNameByLocale(locale, value[0])}</Button>
                    })
                  }
                </div>
              )
            } else {
              return <div />
            }
          })
        }
        {
          <div>
            <Button variant='outlined' href={makeKlookUrl(makeKlookLocale(locale), null, null)} style={{ margin: 14, backgroundColor: 'white' }} onClick={() => {
              logToFb('aff_klook', { device: os, tcc, locale, city: 'coupon' })
            }}>{text.klookButton}</Button>
          </div>
        }
      </Card>
      <Card variant='outlined' style={{ width: '100%', marginTop: 15, backgroundColor: cardBackgroud }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: '#333333', padding: 10 }}>
          # {text.hotelTitle}
        </div>
        <div>
          <img src={agoda} width='15%' height='15%' style={{ marginLeft: 13 }} />
          <Button variant='outlined' href={text.agodaButtonLink} style={{ marginBottom: 15, marginLeft: 36, backgroundColor: 'white' }} onClick={() => {
            logToFb('aff_agoda', { device: os, tcc, locale })
          }}>{text.agodaButton}</Button>
          {/* <Button href='https://www.agoda.com/partners/partnersearch.aspx?pcs=1&cid=1818864&hl=en-us&city=16440&checkIn=2023-10-14&checkOut=2023-10-15'>다낭 호텔 검색</Button> */}
        </div>
      </Card>
      <Card variant='outlined' style={{ width: '100%', marginTop: 15, backgroundColor: cardBackgroud }}>
        <div style={{ fontSize: 20, fontWeight: 'bold', color: '#333333', padding: 10 }}>
          # {text.simCardTitle}
        </div>
        <div>
          <img src={airalo} width='20%' height='20%' style={{ marginLeft: 10 }} />
          <Button variant='outlined' href={text.airaloButtonLink} style={{ marginBottom: 11, marginLeft: 20, backgroundColor: 'white' }} onClick={() => {
            logToFb('aff_airalo', { device: os, tcc, locale })
          }}>{text.airaloButton}</Button>
        </div>
      </Card>
    </div>
  )
}

// http://localhost:3000/affiliate?os=ios&tcc=VN:JP&locale=ko-KR&type=prd&city=&st=2024-11-20&ed=2024-11-24&on=