import React, { useRef, useState } from 'react'
import { useObserver } from 'mobx-react'
import { Button, Grid, Snackbar, TextField, Typography } from '@material-ui/core'
import { userStore } from 'data/userStore'
import { useHistory } from 'react-router-dom'
import { themeColor } from 'styles/styles'
import { apiPostUser } from 'data/apis'
import { stText } from 'data/stringData'
import { Alert } from '@material-ui/lab'

export default function ProfilePage() {
  const history = useHistory()
  const nickRef: React.Ref<any> = useRef()
  const [editing, setEditing] = useState(false)
  const [failMsg, setFailMsg] = useState('')
  return useObserver(() => {
    return (
      <div>
        <Grid container direction='column' spacing={1}>
          <Grid item xs={12}>
            <Typography variant='h4' style={{ color: themeColor }}>Profile</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography color='secondary' variant='caption'>ID(email)</Typography>
            <Typography variant='subtitle1'>{userStore.email}</Typography>

          </Grid>
          {!editing && <Grid item xs={12}>
            <Typography color='secondary' variant='caption'>Nick Name</Typography>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
              <Typography variant='subtitle1'>{userStore.nickname}</Typography>
              <Button variant='outlined' size='small' color='primary' style={{ height: 25, marginLeft: 20 }} onClick={() => setEditing(true)}>{stText.EDIT()}</Button>
            </div>
          </Grid>}
          {editing && <Grid item xs={12}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'left' }}>
              <TextField id='title' margin='dense' inputRef={nickRef} label='Nick Name' variant='outlined' inputProps={{ maxLength: 12 }} style={{ width: '100%', height: 30 }} />
              <Button variant='contained' color='secondary' size='small' style={{ marginLeft: 15, marginTop: 9 }} onClick={() => setEditing(false)}>{stText.CANCEL()}</Button>
              <Button variant='contained' color='primary' size='small' style={{ marginLeft: 15, marginTop: 9 }} onClick={async () => {
                const nick = nickRef.current.value as string ?? ''
                if (nick.length < 3 || nick.length > 15) {
                  setFailMsg(stText.nicknameLengthMustOver3())
                } else {
                  await apiPostUser({ nickname: nick })
                  await userStore.fetchUserInfo()
                  setEditing(false)
                }
              }}>{stText.SAVE()}</Button>
            </div>
          </Grid>}
          <Grid item xs={12}>
            <Button variant='contained' color='primary' style={{ width: '100%', marginTop: 6 }} onClick={() => history.goBack()}>{stText.BACK()}</Button>
          </Grid>
        </Grid>
        <Snackbar open={failMsg !== ''} autoHideDuration={6000} onClose={() => setFailMsg('')} >
          <Alert severity='error'>
            {failMsg}
          </Alert>
        </Snackbar>
      </div>
    )
  })
}