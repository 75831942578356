import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, IconButton, InputBase } from '@material-ui/core'
import { useStyles } from 'styles/styles'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { postStore } from 'data/postStore'
import { useObserver } from 'mobx-react'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import { grey } from '@material-ui/core/colors'
import { apiDefineProperty } from 'mobx/dist/internal'
import { apiSearchSocial } from 'data/apis'
import { stText } from 'data/stringData'

export default function PostSearchBox() {
  const classes = useStyles()

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const searchRef = useRef<any>()
  const [searched, setSearched] = useState(false)

  return useObserver(() => {
    console.log('render')
    return (
      <Box component='form' border={1} borderRadius={5} borderColor={grey[300]} style={{ padding: '2px 4px', display: 'flex', alignItems: 'center', width: '98%', height: 35 }}>
        <InputBase
          inputRef={searchRef}
          placeholder={stText.Search()}
          style={{ marginLeft: 10, flex: 1 }} />
        {
          searched && <IconButton style={{ padding: 10 }} onClick={(v) => {
            postStore.fetchList(postStore.currCC)
            setSearched(false)
            searchRef.current.value = ''
          }}>
            <ClearIcon color='error' />
          </IconButton>
        }
        <IconButton type='submit' aria-label='search' style={{ padding: 10 }} onClick={(v) => {
          v.preventDefault()
          const word = searchRef.current.value as string
          console.log('searchRefd', word)
          if (word) {
            apiSearchSocial(word, postStore.currCC).then(value => {
              console.log('value', value)
              postStore.setPostList(value.data.docs)
              setSearched(true)
            })
          }
        }}>
          <SearchIcon />
        </IconButton>
      </Box>
    )
  })
}
