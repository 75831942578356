import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, makeStyles, Snackbar, TextField, Typography } from '@material-ui/core'
import { AccountCircleOutlined, Add } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { apiPostUser } from 'data/apis'
import { getCountry } from 'data/countryData'
import { postStore } from 'data/postStore'
import { stText } from 'data/stringData'
import { userStore } from 'data/userStore'
import { useObserver } from 'mobx-react'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { accentColor } from 'styles/styles'
import CountryChips from './post/CountryChips'
import { getMainBoardLabel, getSubBoardLabel } from './post/postData'
import PostList from './post/PostList'
import PostSearchBox from './post/PostSearchBox'


export default function SocialMainPage() {
  const history = useHistory()
  const location = useLocation()

  // params os=android&tcc=us;ca;dm;in;eg;kr;la;lk;cn&locale=en-US
  const params = qs.parse(location.search, { ignoreQueryPrefix: true })
  const tcc = params.tcc as string
  console.log(tcc)
  const [openNickSet, setOpenNickSet] = useState(false)
  const [disableButton, setDisableButton] = useState(false)
  const [failMsg, setFailMsg] = useState('')
  const nickRef: React.Ref<any> = useRef()

  if (tcc) {
    userStore.setTripCCs(tcc.split(';').map(v => v.toUpperCase()))
  }
  if (params.os) {
    userStore.setOS(params.os as string)
  }
  if (params.locale) {
    userStore.setLocale(params.locale as string)
  }

  if (params.type) {
    localStorage.setItem('type', params.type as string)
  }

  useEffect(() => {
    if (postStore.currCC === '') {
      postStore.setCC(userStore.getUserCC())
    }

    if (userStore.nickname === '') {
      userStore.fetchUserInfo().then((ret) => {
        if (ret === -1) {
          console.log('no user')
          setOpenNickSet(true)
        }
      })
    }
  }, [])

  const handleNickname = (nickname?: string) => {
    setDisableButton(true)
    const userBody = {} as any
    if (params.locale) {
      userBody.locale = params.locale
    }

    if (nickname) {
      userBody.nickname = nickname
    }

    // create new user
    apiPostUser(userBody).then(res => {
      userStore.setNickName(res.data.user.nickname)
      userStore.setFbid(res.data.user.userfbid)
      userStore.setEmail(res.data.email)
    }).catch(e => {
      console.log('err', e)
      setFailMsg(stText.serverError())
    }).finally(() => setOpenNickSet(false))
  }

  return useObserver(() => (
    <div>
      <Grid container direction='column'>
        <Grid item xs={12}>
          <CountryChips />
        </Grid>
        <Grid item xs={12}>
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', marginLeft: 5 }}>
            <div>
              <Typography variant='h6'>{getMainBoardLabel(postStore.currCC)}</Typography>
              <span style={{ fontSize: 13 }}>{getSubBoardLabel(postStore.currCC)}</span>
            </div>
            <IconButton aria-label='account' component='span' color='primary' onClick={(ev: any) => {
              history.push('/social/m/profile')
            }}><AccountCircleOutlined style={{ width: 32, height: 32 }} />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15, marginLeft: 4, marginRight: 5 }}>
          <PostSearchBox />
        </Grid>
        <Grid item xs={12}>
          <PostList />
        </Grid>
      </Grid>
      <Fab style={{ position: 'fixed', right: 18, bottom: 50, background: accentColor, color: 'white' }} onClick={() => {
        history.push('/social/m/write')
      }}><Add />
      </Fab>
      <Dialog open={openNickSet} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>{stText.setNickname()}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {stText.writeNicknameUsingCommunity()}
          </DialogContentText>
          <TextField
            inputRef={nickRef}
            autoFocus
            margin='dense'
            id='nickname'
            label='Nickname'
            disabled={disableButton}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' disabled={disableButton} onClick={() => handleNickname()}>
            {stText.CLOSE_NICKNAME()}
          </Button>
          <Button color='primary' disabled={disableButton} onClick={() => {
            const nickname = nickRef.current.value as string ?? ''
            if (nickname.length < 3 || nickname.length > 15) {
              setFailMsg(stText.nicknameLengthMustOver3)
            } else {
              handleNickname(nickname)
            }
          }}>
            {stText.SAVE()}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={failMsg !== ''} autoHideDuration={6000} onClose={() => setFailMsg('')} >
        <Alert severity='error'>
          {failMsg}
        </Alert>
      </Snackbar>
    </div>
  ))
}

