import { Button } from '@material-ui/core'
import React from 'react'

export default function BottomComp() {
  return (
    <div>
      <Button variant='text' style={{ color: 'rgb(10,10,10)' }} onClick={() => {
        window.location.href = '/pp.html'
      }}>Privacy Policy</Button>
      <Button variant='text' style={{ color: 'rgb(10,10,10)' }} onClick={() => {
        window.location.href = '/termofuse.html'
      }}>Terms of use</Button>
      <Button variant='text' style={{ color: 'rgb(10,10,10)' }} onClick={() => {
        window.location.href = 'mailto:wooman.labs@gmail.com'
      }}>Contact us</Button>
    </div>
  )
}
