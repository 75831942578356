import { userStore } from './userStore'

const korean = () => userStore.getUserLC() === 'KO'

export const stText = {
  talkAboutTripInfo: (p1: string) => korean() ? `전세계 여행자들과 ${p1} 여행에 관해 이야기 하세요 😁` : `Talk about ${p1} travel tips with travelers around the world 😁`,
  talkAboutAnything: () => korean() ? '전세계 여행자들과 자유롭게 이야기 하세요 🌏' : 'Talk about anything with travelers around the world 🌏',
  findingTravelBuddySub: () => korean() ? '여행을 함께할 동행을 찾으세요 🙋' : 'Find a travel buddy 🙋',
  areYouSureYouWantToDelete: () => korean() ? '정말 삭제 하시겠습니까?' : 'Are you sure you want to delete?',
  doYouWantToEdit: () => korean() ? '수정 하시겠습니까?' : 'Do you want to edit?',
  writeNicknameUsingCommunity: () => korean() ? '커뮤니티에서 사용할 닉네임을 설정해주세요. 닉네임은 언제든 변경 가능합니다.' : 'Please set a nickname to use in the community. You can change it anytime.',
  writeTitle: () => korean() ? '제목을 작성하세요' : 'Please enter a title',
  writeContents: () => korean() ? '내용을 작성하세요' : 'Please enter contents',
  writeComments: () => korean() ? '코멘트를 작성하세요' : 'Please enter comments',
  nicknameLengthMustOver3: () => korean() ? '닉네임은 3 ~ 15자로 작성하세요' : 'Please enter 3 ~ 15 characters.',
  serverError: () => korean() ? '저장에 실패 하였습니다. 잠시후 다시 이용해 주세요.' : 'Fail to save this! please try again later.',
  comments: () => korean() ? '댓글을 남겨 보세요.' : 'Enter your comments here...',
  setNickname: () => korean() ? '닉네임 설정' : 'Nickname',
  freeForum: () => korean() ? '자유게시판' : 'Free Forum',
  findingTravelBuddy: () => korean() ? '동행 찾기' : 'Travel Partners',
  Title: () => korean() ? '제목' : 'Title',
  Contents: () => korean() ? '내용' : 'Contents',
  CityOrRegion: () => korean() ? '도시 또는 지역명' : 'City or Region',
  Search: () => korean() ? '검색' : 'Search',
  PHOTO: () => korean() ? '사진' : 'PHOTO',
  SAVE: () => korean() ? '저장' : 'SAVE',
  DELETE: () => korean() ? '삭제' : 'DELETE',
  EDIT: () => korean() ? '수정' : 'EDIT',
  BACK: () => korean() ? '뒤로' : 'BACK',
  CANCEL: () => korean() ? '취소' : 'CANCEL',
  CLOSE_NICKNAME: () => korean() ? '닫기(자동 설정)' : 'CLOSE(RANDOM NAME)',

  NO: () => korean() ? '아니오' : 'NO'
}
