import Resizer from 'react-image-file-resizer'
import { userStore } from './userStore'

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min //최댓값은 제외, 최솟값은 포함
}

export function getDateString(msec: number) {
  const locale = userStore.locale

  const dateOption: Intl.DateTimeFormatOptions = {
    hour12: false,
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  }
  return locale ? new Date(msec).toLocaleString(locale, dateOption) : new Date(msec).toLocaleString(undefined, dateOption)
}

export async function imageResizer(file: File) {
  // https://www.npmjs.com/package/react-image-file-resizer
  const tempFunc = (file: File) =>
    new Promise<File>((resolve) =>
      Resizer.imageFileResizer(file,
        600,  // max-width
        800,  // max-height
        'WEBP',
        80,    // quality
        0,    // rotation
        (uri) => {
          resolve(uri as File)
        },
        'file'
      )
    )
  return await tempFunc(file)

}