import { colors, createTheme, makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    border: 0,
    borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'red',
    height: 48,
    padding: '0 30px'
  }
})

export const noCapButtonStyle = makeStyles({
  label: {
    textTransform: 'none'
  }
})

export const accentColor = '#00BCD4'
export const themeColor = '#008ab6'
export const rootTheme = createTheme({
  palette: {
    primary: {
      main: accentColor,
      contrastText: '#fff'
    },
    secondary: {
      main: '#F8DA97'
    }
  }
})