// https://www.klook.com/en-US/city/28-tokyo-things-to-do/?aid=13859
// https://www.klook.com/ko/city/28-tokyo-things-to-do/?aid=13859
// en-HK
// th // 태국
// vi // 벳남
// ja // 일본
// id // 인도네시아
// zh-HK
// zh-TW
/**
 * 맞춤 매개변수	이벤트 수	총 사용자
(전체) 항목 81개
2.2천
1.4천
JP	521	437
VN	287	238
KR	278	207
TW	121	96
PH	108	85
TH	81	57
AU	77	65
GB	51	33
FR	49	40
US	42	29
IT	41	30
ES	40	30
 */

export const klookCityNameMap = new Map<string, string>()
export const klookCountryMap = new Map<string, Map<string, string>>()

// jp japan
const jpCityMap = new Map<string, string>()
jpCityMap.set('tokyo', '28-tokyo-things-to-do')
jpCityMap.set('kyoto', '30-kyoto-things-to-do')
jpCityMap.set('nagoya', '71-nagoya-things-to-do')
jpCityMap.set('osaka', '29-osaka-things-to-do')
jpCityMap.set('hokkaido', '32-hokkaido-things-to-do')
jpCityMap.set('sapporo', '133938-sapporo-things-to-do')
jpCityMap.set('kyushu', '36-kyushu-area-things-to-do')    //규슈 / 후쿠오카 & 구마모토
jpCityMap.set('kanagawa', '6806-kanagawa-prefecture-things-to-do') // 가나가와 & 하코네
jpCityMap.set('okinawa', '6484-okinawa-things-to-do')
jpCityMap.set('miyako', '24144-miyako-island-things-to-do')
// klookCityMap.set('ishigaki', '10000043-ishigaki-island-yaeyama-islands-things-to-do')    // 이시가키섬 & 야에야마 제도
// klookCityMap.set('chugoku', '343-chugoku-area-things-to-do')    // 주고쿠 / 히로시마
klookCountryMap.set('JP', jpCityMap)

// jp name
klookCityNameMap.set('tokyo:ko', '도쿄')
klookCityNameMap.set('tokyo:en-US', 'Tokyo')
klookCityNameMap.set('kyoto:ko', '교토')
klookCityNameMap.set('kyoto:en-US', 'Kyoto')
klookCityNameMap.set('nagoya:ko', '나고야')
klookCityNameMap.set('nagoya:en-US', 'Nagoya')
klookCityNameMap.set('osaka:ko', '오사카')
klookCityNameMap.set('osaka:en-US', 'Osaka')
klookCityNameMap.set('hokkaido:ko', '훗카이도')
klookCityNameMap.set('hokkaido:en-US', 'Hokkaido')
klookCityNameMap.set('sapporo:ko', '삿포르')
klookCityNameMap.set('sapporo:en-US', 'Sapporo')
klookCityNameMap.set('kyushu:ko', '규슈/후쿠오카')
klookCityNameMap.set('kyushu:en-US', 'Kyushu')
klookCityNameMap.set('kanagawa:ko', '가나가와')
klookCityNameMap.set('kanagawa:en-US', 'Kanagawa')
klookCityNameMap.set('okinawa:ko', '오키나와')
klookCityNameMap.set('okinawa:en-US', 'Okinawa')
klookCityNameMap.set('miyako:ko', '미야코지마')
klookCityNameMap.set('miyako:en-US', 'Miyako Island')
// klookCityNameMap.set('ishigaki:ko', '이시가키')
// klookCityNameMap.set('ishigaki:en-US', 'ishigaki')
// klookCityNameMap.set('chugoku:ko', '주코쿠')
// klookCityNameMap.set('chugoku:en-US', 'chugoku')

// vn japan
const vnCityMap = new Map<string, string>()
vnCityMap.set('hochiminh', '33-ho-chi-minh-city-things-to-do')
vnCityMap.set('hanoi', '34-hanoi-things-to-')
vnCityMap.set('hue', '35-hue-things-to-do')
vnCityMap.set('hoian', '75-hoi-an-things-to-do')
vnCityMap.set('danang', '74-da-nang-things-to-do')
vnCityMap.set('phuquoc', '130-phu-quoc-things-to-do')
vnCityMap.set('dalat', '207-da-lat-things-to-do')
vnCityMap.set('nhatrang', '208-nha-trang-things-to-do')
vnCityMap.set('sapa', '290-sapa-things-to-do')
vnCityMap.set('halong', '486-halong-things-to-do')
klookCountryMap.set('VN', vnCityMap)


klookCityNameMap.set('hochiminh:ko', '호치민')
klookCityNameMap.set('hochiminh:en-US', 'Ho Chi Minh')
klookCityNameMap.set('hanoi:ko', '하노이')
klookCityNameMap.set('hanoi:en-US', 'Hanoi')
klookCityNameMap.set('hue:ko', '후에')
klookCityNameMap.set('hue:en-US', 'Hue')
klookCityNameMap.set('hoian:ko', '호이안')
klookCityNameMap.set('hoian:en-US', 'Hoi An')
klookCityNameMap.set('danang:ko', '다낭')
klookCityNameMap.set('danang:en-US', 'Da Nang')
klookCityNameMap.set('phuquoc:ko', '푸꾸옥')
klookCityNameMap.set('phuquoc:en-US', 'Phu Quoc')
klookCityNameMap.set('dalat:ko', '달랏')
klookCityNameMap.set('dalat:en-US', 'Da Lat')
klookCityNameMap.set('nhatrang:ko', '나트랑')
klookCityNameMap.set('nhatrang:en-US', 'Nha Trang')
klookCityNameMap.set('sapa:ko', '사파')
klookCityNameMap.set('sapa:en-US', 'Sapa')
klookCityNameMap.set('halong:ko', '하롱')
klookCityNameMap.set('halong:en-US', 'Halong')


export function getCityNameByLocale(localeStr: string, cityId: string) {
  const newlocale = makeKlookLocale(localeStr)
  return klookCityNameMap.get(cityId + ':' + newlocale)
}

export function makeKlookUrl(lang: string, country: string|null, city: string|null) {
  if(country == null || city == null) {
    return `https://www.klook.com/${lang}/deals/?aid=13859`
  }
  const cityMap = klookCountryMap.get(country)
  const cityUrl = cityMap?.get(city)
  return `https://www.klook.com/${lang}/city/${cityUrl}/?aid=13859`
}


export function makeKlookLocale(localeStr: string) {
  if (localeStr.startsWith('ko')) {
    return 'ko'
  } else {
    return 'en-US'
  }
}