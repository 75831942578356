import { observable } from 'mobx'
import { apiGetPosts } from './apis'
import { userStore } from './userStore'

export const postStore = observable({
  dataPerPage: 10,
  currPage: 1,
  postCount: 0,
  postList: [] as any[],
  currForum: 'country',
  currCC: '',
  setPage(num: number) {
    this.currPage = num
  },
  setForum(forum: string) {
    this.currForum = forum
  },
  setCC(cc: string) {
    this.currCC = cc
  },
  setPostList(data: any[]) {
    this.postList = data
    this.postCount = data.length
  },
  async fetchList(cc: string) {
    const res = await apiGetPosts(cc)
    this.postList = res.data ?? []
    this.postCount = this.postList.length
  }
})
