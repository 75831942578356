import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import Router from './components/Router'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { rootTheme } from 'styles/styles'

ReactDOM.render(
  <ThemeProvider theme={rootTheme}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  </ThemeProvider>
  ,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
